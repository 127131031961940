import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Seo } from "../gatsby-components"
import { Layout, Section } from "../components"
import {
  FlexibleGridBlock,
  FlexibleGridBlockItem,
  FlexibleGridBlockStack,
  FlexibleGridBlockImage,
} from "../blocks"
import { layout } from "../temp-content/layout"

const IdeenPage = () => {
  const IdeaHeading = ({ children }: any) => (
    <h2 className="relative pt-12 font-semibold uppercase tracking-wider sm:pt-16 lg:text-xl">
      <span className="pointer-events-none absolute top-0 left-0 h-8 text-5xl">
        &bull;
      </span>
      {children}
    </h2>
  )
  return (
    <>
      <Seo title="Ideen" />
      <Layout {...layout}>
        <Section noPaddingTop className="grid gap-8 sm:gap-12">
          {/* Wettbewerb Steinrausch */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/steinrausch.jpg"
                      alt="Wettbewerb Steinrausch"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <IdeaHeading>
                  Wettbewerb
                  <br />
                  Steinrausch
                </IdeaHeading>
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Wettbewerb Merzig (2 rows) */}
          <div>
            <FlexibleGridBlock
              cols={3}
              className="gap-1 sm:gap-8"
              items={[
                <FlexibleGridBlockItem
                  className="order-2 sm:order-1"
                  colSpan={1}
                >
                  <IdeaHeading>
                    Wettbewerb
                    <br />
                    Merzig
                  </IdeaHeading>
                </FlexibleGridBlockItem>,
                <FlexibleGridBlockItem
                  className="order-1 sm:order-2"
                  colSpan={2}
                >
                  <FlexibleGridBlockImage
                    image={
                      <StaticImage
                        src="../images/ideen-temp/wettbewerb-merzig-1.jpg"
                        alt="Wettbewerb Merzig 1"
                      />
                    }
                  />
                </FlexibleGridBlockItem>,
              ]}
            />
            {/* Row 2 */}
            <FlexibleGridBlock
              cols={2}
              className="hidden sm:grid"
              items={[
                <FlexibleGridBlockItem>
                  <FlexibleGridBlockImage
                    image={
                      <StaticImage
                        src="../images/ideen-temp/wettbewerb-merzig-2.jpg"
                        alt="Wettbewerb Merzig 2"
                      />
                    }
                  />
                </FlexibleGridBlockItem>,
                <FlexibleGridBlockItem>
                  <FlexibleGridBlockImage
                    image={
                      <StaticImage
                        src="../images/ideen-temp/wettbewerb-merzig-3.jpg"
                        alt="Wettbewerb Merzig 3"
                      />
                    }
                  />
                </FlexibleGridBlockItem>,
              ]}
            />
          </div>
          {/* Büroerweiterung */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/bueroerweiterung.jpg"
                      alt="Büroerweiterung"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <IdeaHeading>Büroerweiterung</IdeaHeading>
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Städtebaukonzept Orscholz */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem className="order-2 sm:order-1" colSpan={1}>
                <IdeaHeading>
                  Städtebaukonzept
                  <br />
                  Orscholz
                </IdeaHeading>
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem className="order-1 sm:order-2" colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/orscholz.jpg"
                      alt="Städtekonzept Orscholz"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Grundschule Heusweiler */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/grundschule-heusweiler.jpg"
                      alt="Grundschule Heusweiler"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <IdeaHeading>
                  Grundschule
                  <br />
                  Heusweiler
                </IdeaHeading>
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Wettbewerb Inexio */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem className="order-2 sm:order-1" colSpan={1}>
                <IdeaHeading>
                  Wettbewerb
                  <br />
                  Inexio
                </IdeaHeading>
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem className="order-1 sm:order-2" colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/wettbewerb-inexio.jpg"
                      alt="Wettbewerb Inexio"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Parkhaus Merzig */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/parkhause-merzig.jpg"
                      alt="Parkhaus Merzig"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <IdeaHeading>
                  Parkhaus
                  <br />
                  Merzig
                </IdeaHeading>
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Wohnhaus Merzig */}
          <FlexibleGridBlock
            cols={3}
            className="gap-1 sm:gap-8"
            items={[
              <FlexibleGridBlockItem className="order-2 sm:order-1" colSpan={1}>
                <IdeaHeading>
                  Wohnhaus
                  <br />
                  Merzig
                </IdeaHeading>
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem className="order-1 sm:order-2" colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/ideen-temp/wohnhaus-merzig.png"
                      alt="Wohnhaus Merzig"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
        </Section>
      </Layout>
    </>
  )
}

export default IdeenPage
